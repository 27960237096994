import React from "react";
import Layout from "../components/layout";
import DocumentIcon from "../assets/images/icons/document.svg";
import "../styles/pages/downloads.scss";

const data = [
  {
    category: "Forms",
    files: [
      {
        name: "NLRRWA Membership Form.pdf",
        lastUpdated: "01-Jan-2025",
        url: "https://nlrrwa-assets-prod.s3.us-east-005.backblazeb2.com/documents/NLRRWA-MEMBERSHIP-FORM.pdf",
      },
    ],
  },
  {
    category: "Layouts",
    files: [
      {
        name: "Semmancheri Nookampalayam Link Road Layout.pdf",
        lastUpdated: "04-Dec-2021",
        url: "https://res.cloudinary.com/sivadass/image/upload/v1639316571/nlrrwa/documents/Semmancheri-Combine-Layout.pdf",
      },
    ],
  },
  {
    category: "Branding",
    files: [
      {
        name: "NLRRWA Letterhead.pdf",
        lastUpdated: "12-Dec-2021",
        url: "https://res.cloudinary.com/sivadass/image/upload/v1639312283/nlrrwa/branding/NLRRWA-Letterhead.pdf",
      },
      {
        name: "NLRRWA High Resolution Logo.png",
        lastUpdated: "12-Dec-2021",
        url: "https://res.cloudinary.com/sivadass/image/upload/v1639312423/nlrrwa/branding/NLRRWA-High-Resolution-Logo.png",
      },
    ],
  },
];

export default function Downloads() {
  return (
    <Layout title="Downloads">
      <h1 className="page-title">Downloads</h1>
      <div className="download-list">
        {data.map((d) => {
          return (
            <div className="download-list-item">
              <h2>{d.category}</h2>
              <ul>
                {d.files.map((f) => {
                  return (
                    <li>
                      <a href={f.url}>
                        <div>
                          <img src={DocumentIcon} alt="Document" />
                        </div>
                        <div>
                          <h3>{f.name}</h3>
                          <p>{f.lastUpdated}</p>
                        </div>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
    </Layout>
  );
}
